<template>
  <!-- Apply Modal -->
  <Modal size="3xl">
    <template #header>
      <div class="flex items-center justify-between w-full overflow-hidden">
        <div class="flex items-center w-4/6">
          <img :src="jobDescription.employer.logo_url" class="h-8 w-8 rounded-full object-cover mr-2" alt="companyLogo" />
          <p class="font-cooper font-normal lg:text-lg text-base text-brand-black/70 capitalize">Apply as a {{ role(jobDescription) }}</p>
        </div>
        <Close @click.stop="emits('close-modal')" class="cursor-pointer" />
      </div>
      <div class="w-full bg-gray-200 mx-0 overflow-hidden border border-gray-300 mt-2">
        <div class="bg-brand-primary text-xs leading-none py-1" style="width: 0%"></div>
      </div>
    </template>
    <template #body>
      <div v-if="showMesageModal">
        <div class="flex items-center justify-between mb-2.5">
          <p class="text-brand-black text-opacity-90 text-base font-bold">Send us a message</p>
          <Close @click.stop="showMesageModal = false" class="cursor-pointer" />
        </div>
        <textarea
          name="coverLetter"
          v-model="message"
          cols="30"
          rows="6"
          placeholder="Talk to us"
          class="border border-[#240F1F1A]/10 rounded-xl focus:outline-none w-full focus:border-[#240F1F1A]/10 focus:ring-0"
        ></textarea>
      </div>
      <div v-else>
        <p class="text-brand-black text-opacity-90 text-base font-bold mb-2.5">Why are you the best person for this role?</p>

        <textarea
          data-test="cover-letter"
          name="coverLetter"
          v-model="note"
          cols="30"
          rows="6"
          placeholder="Tell us why you’re a good fit"
          class="border border-[#240F1F1A]/10 bg-[#240F1F1A] rounded-xl focus:outline-none w-full focus:border-[#240F1F1A]/10 focus:ring-0"
        ></textarea>
      </div>
    </template>
    <template #footer>
      <div class="container">
        <div class="sm:grid md:flex justify-between md:flex-row columns-1 md:columns-2">
          <div class="flex flex-row items-center justify-between md:space-x-32 mb-3 columns-2">
            <div class="grid grid-cols-1 justify-between md:space-x-2 mb-3 columns-1 text-brand-black">
              <p class="text-base font-bold mb-2">Hiring Contact</p>
              <div class="flex flex-row columns-2 w-full">
                <div class="w-1/3">
                  <img :src="hiring_img" alt="profile" class="h-8 w-8 rounded-full object-cover mr-2" />
                </div>
                <div class="w-2/3">
                  <p class="font-bold text-sm m-0 capitalize">{{ hiring_name }}</p>
                  <p class="text-opacity-60 font-light text-xs order-last" v-if="hiring_position">{{ hiring_position }}</p>
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-2 md:flex md:flex-col items-end ml-32">
              <div class="flex items-center cursor-pointer" @click="showMesageModal = true">
                <Chat fill="#6D2E5D" />
                <p class="text-brand-color font-bold text-base hidden md:block ml-2">Send Message</p>
              </div>
            </div>
          </div>
          <div class="sm:grid md:flex md:flex-col items-center">
            <Button
              data-test="next-button"
              label="Next"
              v-if="!showMesageModal"
              :color="disable ? 'blackOpacity' : 'primary'"
              btnSize="w-full md:w-28"
              :disabled="disable"
              @click="interviewQuestions"
              :loading="isLoading"
            />
            <Button
              v-if="showMesageModal"
              label="Send"
              :color="disableMessage ? 'blackOpacity' : 'primary'"
              btnSize="w-full md:w-28"
              :disabled="disableMessage"
              @click="sendMessage"
              class="pre-btn"
            />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { alert } from '@/utils/helper';
import Chat from '@/assets/icons/chat.svg?inline';
import Close from '@/assets/icons/close.svg?inline';
import Button from '@/components/Button.vue';
import Modal from '@/components/Modal.vue';

const store = useStore();

const emits = defineEmits(['close-modal', 'show-question']);
const props = defineProps({
  jobDescription: {
    type: Object,
    required: true,
  },
});
const disable = ref(true);

const isSending = ref(false);
const showMesageModal = ref(false);
const disableMessage = ref(true);
const note = ref('');
const message = ref('');
const heading_text = ref('');
const isLoading = ref(false);

const role = (job) => job.role_title || job.title_name;

const hiring_img = computed(() => {
  if (props.jobDescription.postedBy) return props.jobDescription.postedBy.avatar_url;
  return props.jobDescription.employer.logo_url;
});
const hiring_name = computed(() => {
  if (props.jobDescription.postedBy) return props.jobDescription.postedBy.name;
  return props.jobDescription.employer.name;
});

const hiring_position = computed(() => {
  if (props.jobDescription.postedBy) {
    const position = props.jobDescription.postedBy.position ? `${props.jobDescription.postedBy.position},` : '';
    return `${position} ${props.jobDescription.employer.name}`;
  }
  return null;
});

const interviewQuestions = () => {
  store.commit('global/setJobForm', { key: 'note', value: note.value });
  emits('show-question');
};

watch(note, (newQuestion, oldQuestion) => {
  if (newQuestion) {
    disable.value = false;
  } else disable.value = true;
});

watch(message, (newQuestion, oldQuestion) => {
  if (newQuestion) {
    disableMessage.value = false;
  } else disableMessage.value = true;
});

const sendMessage = async () => {
  try {
    if (!isSending.value && message.value) {
      isSending.value = true;
      await store.dispatch('conversation/createConversation', {
        receiver_identifier: props.jobDescription.employer.participant_identifier,
        type: 'text',
        url: null,
        content: message.value,
      });

      message.value = '';
      isSending.value = false;
      showMesageModal.value = false;

      alert('Message Sent', 'success');
    }
  } catch (error) {
    alert(errorMessage(error), 'error');
  }
};

onMounted(async () => {
  const form = store.getters['global/getJobForm'];
  if (form.note) {
    note.value = form.note;
  }
  process.env.NODE_ENV === 'development' ? console.log(form) : '';
});
</script>
<style lang="scss" scoped>
.description::v-deep {
  line-height: 1.8rem;

  ul {
    list-style: disc;
    margin-left: 1rem;
    padding-left: 1rem;
  }
}
</style>
