<template>
  <main>
    <div v-if="tab === 1">
      <div class="bg-[#FEFAF1] border border-[#DEDEDE] rounded-xl p-2 my-8">
        <div class="flex items-center justify-between">
          <p class="text-[#8F8F8F] text-sm">You are inviting someone to apply for this job</p>
          <a href="/Referral.csv" download="candidate.csv" class="inline-flex items-center space-x-2 bg-light-yellow p-3 rounded-lg">
            <img src="../../assets/icons/document.svg" alt="" />
            <span class="text-brand-primary text-sm font-albertSemiBold cursor-pointer">Download Template</span>
          </a>
        </div>
      </div>
      <form class="text-center p-8" id="csvForm" enctype="multipart/form-data">
        <img src="../../assets/icons/drag.svg" alt="" class="mx-auto" />
        <div class="my-4 mt-8">
          <h2 class="text-[18px] text-brand-black font-semibold">Drag & Drop here to upload</h2>
          <small class="inline-block text-[14px] my-2 text-[#8F8F8F]">Or select file from your computer</small>
        </div>
        <tam-button color="black" label="Upload File" :icon="Uploadline" class="rounded-xl text-[14px]" />
        <label
          for="dropzone-file"
          class="bg-brand-black p-4 text-white rounded-lg px-6 text-[14px] inline-flex items-center space-x-2 disabled:bg-brand-black/30 cursor-pointer"
        >
          <input type="file" id="dropzone-file" name="file" accept=".csv,.xls,.xlsx" @change="getCSVData" class="hidden" />
          <img src="../../assets/icons/uploadline.svg" class="inline-block" alt="" />
          <span>Upload CSV File</span>
        </label>
      </form>
    </div>
    <div v-if="tab === 2">
      <div class="my-6 flex items-center gap-3">
        <div class="w-full bg-gray-200 mx-0 overflow-hidden border border-[#F0F0F0] rounded">
          <div v-if="tab === 2" class="bg-brand-primary text-xs leading-none py-1" style="width: 90%"></div>
        </div>
        <p v-if="tab === 2">90%</p>
      </div>

      <!-- <form> -->
      <div class="pt-4 mb-6 overflow-scroll h-[600px]">
        <div class="overflow-x-auto custom-scroll">
          <table class="w-full text-sm text-left text-gray-500 p-4">
            <thead class="uppercase bg-brand-black text-white">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-4 text-[13px] whitespace-nowrap font-bold capitalize text-left"
                  v-for="item in tableDetails"
                  :key="item"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody v-if="csvData.length">
              <tr v-for="item in csvData" :key="item" class="bg-white border-b hover:bg-gray-50">
                <td class="px-6 py-4 font-medium whitespace-nowrap">{{ item.first_name }}</td>
                <td class="px-6 py-4 font-medium whitespace-nowrap">{{ item.last_name }}</td>
                <td class="px-6 py-4">{{ item.email_address }}</td>
                <td class="px-6 py-4">{{ item.linkedIn_url }}</td>
                <td class="px-6 py-4">{{ item.link_to_cv }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="6">
                  <div class="p-4">
                    <p>No data uploaded yet</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="pt-2 flex space-x-6 items-center">
        <button type="button" @click.prevent="closeModal" class="bg-white p-4 py-3 border border-brand-black rounded-lg w-1/2">Cancel</button>
        <button type="button" class="bg-brand-black text-white p-4 py-3 rounded-lg w-1/2" @click.prevent="addCandidate">Submit</button>
      </div>
      <!-- </form> -->
    </div>
    <!-- <div v-if="tab === 3">
      <div class="my-6 flex items-center gap-3">
        <div class="w-full bg-gray-200 mx-0 overflow-hidden border border-[#F0F0F0] rounded">
          <div v-if="tab === 3" class="bg-brand-primary text-xs leading-none py-1" style="width: 90%"></div>
        </div>
        <p v-if="tab === 3">90%</p>
      </div>
      <form>
        <div class="space-y-6 mb-6">
          <div>
            <label for="message" class="block text-sm font-medium text-brand-primary"
              >What makes these people stand out? <span class="text-[#FE1578]">*</span></label
            >
            <textarea
              id="message"
              rows="4"
              class="block w-full text-sm text-[#8F8F8F] bg-white rounded-xl border border-[#DEDEDE] focus:ring-0 focus:border-[#DEDEDE] py-4 px-5"
              placeholder="Write your thoughts here..."
            ></textarea>
          </div>

          <div>
            <label for="message" class="block text-sm font-medium text-brand-primary"
              >Tell us in a few words why you recommend them <span class="text-[#FE1578]">*</span></label
            >
            <textarea
              id="message"
              rows="4"
              class="block w-full text-sm text-[#8F8F8F] bg-white rounded-xl border border-[#DEDEDE] focus:ring-0 focus:border-[#DEDEDE] py-4 px-5"
              placeholder="Write your thoughts here..."
            ></textarea>
          </div>

          <div>
            <label for="countries_disabled" class="block text-sm font-medium text-brand-primary"
              >How familiar are you with this person's work? <span class="text-[#FE1578]">*</span></label
            >
            <select
              id="countries_disabled"
              class="bg-white border border-[#DEDEDE] text-[#8F8F8F] text-sm rounded-xl focus:ring-0 focus:border-[#DEDEDE] block w-full py-4 px-5"
            >
              <option selected>Choose a country</option>
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="FR">France</option>
              <option value="DE">Germany</option>
            </select>
          </div>
          <div>
            <label for="countries_disabled" class="block text-sm font-medium text-brand-primary"
              >How open are they to new opportunities? <span class="text-[#FE1578]">*</span></label
            >
            <select
              id="countries_disabled"
              class="bg-white border border-[#DEDEDE] text-[#8F8F8F] text-sm rounded-xl focus:ring-0 focus:border-[#DEDEDE] block w-full py-4 px-5"
            >
              <option selected>Choose a country</option>
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="FR">France</option>
              <option value="DE">Germany</option>
            </select>
          </div>
        </div>
      </form>
      <div class="pt-2 flex space-x-6 items-center">
        <button type="button" @click.prevent="closeModal" class="bg-white p-4 py-3 border border-brand-black rounded-lg w-1/2">Cancel</button>
        <button type="submit" class="bg-brand-black text-white p-4 py-3 rounded-lg w-1/2" @click.prevent="">Submit</button>
      </div>
    </div> -->
  </main>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
// import PapaParse from 'vue-papa-parse';

// const { parseCSV } = usePapaParse();

// const emit = defineEmits(['close']);
// const props = defineProps({
//   jobId: { type: String },
// });

// const store = useStore();
// const toast = useToast();
// const loading = ref(false);
// const details = ref({
//   type: 'multiple',
//   resume_file: '',
// });
// const tableDetails = ref(['First Name', 'Last Name', 'Email Address', 'LinkedIn URL', 'Link to CV']);

// const uploadCSV = async ($event) => {
//   loading.value = true;
//   try {
//     const formData = new FormData(document.querySelector('#resumeForm'));
//     const fileUrl = await store.dispatch('global/uploadFileUrl', formData);
//     details.value.resume_file = fileUrl;
//   } catch (error) {
//     toast.error(errorMessage(error.response.data.message) || 'Something went wrong (Please check internent connection)', {
//       timeout: 5000,
//       hideProgressBar: true,
//     });
//   } finally {
//     loading.value = false;
//   }
// };

// const addCandidate = async () => {
//   try {
//     // console.log('This is me 1');
//     // loading.value = true;
//     // const formElement = document.querySelector('#dropzone-file');
//     // if (!formElement) {
//     //   throw new Error("Form with id 'csvForm' not found in the DOM");
//     // }
//     // console.log('This is me 1 too');
//     // const formData = new FormData(formElement);
//     // console.log('This is me 2');
//     // const fileUrl = await store.dispatch('global/uploadFileUrl', formData);
//     // console.log('This is me 3');
//     // details.value.resume_file = fileUrl;
//     // console.log('This is me 4');
//     const { message } = await store.dispatch('global/referCandidate', { data: formData, jobId: props.jobId });
//     console.log('This is me 5');

//     toast.success(message, {
//       timeout: 3000,
//       hideProgressBar: true,
//     });

//     emit('close');
//   } catch (error) {
//     toast.error(errorMessage(error) || 'Something went wrong (Please check internent connection)', {
//       timeout: 5000,
//       hideProgressBar: true,
//     });
//     loading.value = false;
//   }
// };
</script>

<script>
import Vue from 'vue';
import { alert, errorMessage } from '@/utils/helper';

export default {
  props: {
    jobId: {
      type: String,
      required: true, // Optional: Specify if the prop is required
    },
  },
  data() {
    return {
      csvData: [],
      tab: 1,
      details: {
        type: 'multiple',
        employer_job_id: '',
      },
      tableDetails: ['First Name', 'Last Name', 'Email Address', 'LinkedIn URL', 'Link to CV'],
    };
  },

  methods: {
    closeModal() {
      this.$emit('close');
      this.tab = 1;
    },
    async getCSVData() {
      const self = this;

      const file = document.querySelector('#dropzone-file').files[0];
      if (!file) {
        this.$toast.error('No file selected. Please upload a file.');
        return;
      }
      this.csvData = [];
      this.$papa.parse(file, {
        download: true,
        header: true,
        skipEmptyLines: true,
        complete: function (answer) {
          for (let i = 0; i < answer.data.length; i++) {
            self.csvData.push(answer.data[i]);
          } // Direct assignment to avoid duplicates
          self.tab = 2; // Switch to the table view
        },
        error: function (error) {
          this.$toast.error(`Error parsing file: ${error.message}`);
        },
      });

      const fileCsv = document.querySelector('#csvForm');
      const fileInput = fileCsv.querySelector('input[type="file"]');

      if (!fileInput.files || fileInput.files.length === 0) {
        console.error('No file selected!');
        return;
      }

      const filee = fileInput.files[0]; // Get the selected file

      // Create FormData and append the file
      const formData = new FormData();
      formData.append('file', filee); // Add file to FormData
      formData.append('type', this.details.type);
      formData.append('employer_job_id', this.details.employer_job_id);
      // Assign the file directly to details.file, not the FormData
      this.details = formData; // Assign the file, not the entire FormData
    },
    async addCandidate() {
      this.$store
        .dispatch('global/referCandidate', this.details)
        .then(({ message }) => {
          alert(message);
          this.$emit('close');
        })
        .catch((error) => {
          // alert('Something went wrong (Please check internet connection)');
          alert(errorMessage(error), 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.details.employer_job_id = this.jobId; // Assign the prop after the component is mounted
  },
};
</script>
