<template>
  <!-- Apply Modal -->
  <Modal size="3xl" className="overflow-y-scroll">
    <template #header>
      <div class="flex flex-row items-center mb-4 w-full">
        <Back class="cursor-pointer text-brand" @click="emits('show-note')" />
        <p class="text-brand ml-2 cursor-pointer" @click="emits('show-question')">Back</p>
      </div>
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center w-4/6">
          <img :src="jobDescription.employer.logo_url" class="h-8 w-8 rounded-full object-cover mr-2" alt="companyLogo" />
          <p class="font-cooper font-normal lg:text-lg text-base text-brand-black/70 capitalize">Apply as a {{ role(jobDescription) }}</p>
        </div>
        <Close @click.stop="emits('close-modal')" class="cursor-pointer" />
      </div>
      <div class="w-full bg-gray-200 mx-0 overflow-hidden border border-gray-300 mt-2">
        <div class="bg-brand-primary text-xs leading-none py-1" style="width: 40%"></div>
      </div>
    </template>
    <template #body>
      <div v-if="showMesageModal">
        <div class="flex items-center justify-between mb-2.5">
          <p class="text-brand-black text-opacity-90 text-base font-bold">Send us a message</p>
          <Close @click.stop="showMesageModal = false" class="cursor-pointer" />
        </div>
        <textarea
          name="coverLetter"
          v-model="message"
          cols="30"
          rows="6"
          placeholder="Talk to us"
          class="border border-[#240F1F1A]/10 rounded-xl focus:outline-none w-full focus:border-[#240F1F1A]/10 focus:ring-0"
        ></textarea>
      </div>
      <div v-else>
        <p class="text-brand-black text-opacity-90 text-base font-bold mb-2.5 md:mb-3 modal-sub-title">Quick Interview Questions</p>

        <div>
          <div class="my-5 flex">
            <Input
              data-test="notice-period"
              id="notice_period"
              label="What is your notice Period?"
              v-model="notice_period"
              class="border border-[#240F1F1A]/10 rounded-xl focus:outline-none w-full focus:border-[#240F1F1A]/10 focus:ring-0"
            />
          </div>
          <div class="my-5">
            <label for="expected_salary" class="w-full text-brand-black/70 block font-albert mb-3 text-lg"
              >What is your Salary Expectations? (Yearly)</label
            >
            <div
              class="expected_salary columns-2 flex flex-row bg-brand-light/10 rounded-[10px] w-full appearance-none focus:outline-none border border-brand-light"
            >
              <select name="currency" v-model="expected_salary.currency" class="py-3 flex flex-col text-base focus:outline-none w-2/12 self-start">
                <option v-for="(currency, idx) in currencies" :value="currency.key" :key="idx">{{ currency.key }}</option>
              </select>
              <input
                data-test="salary-expectation"
                id="expected_salary"
                v-model="expected_salary.value"
                type="number"
                class="p-3 text-base flex flex-col focus:border-brand-dark focus:border focus:ring-1 focus:outline-none w-10/12 focus:border-[#240F1F1A]/10"
                required
                aria-required="true"
              />
            </div>
          </div>
          <div v-if="questions">
            <div class="my-5" v-for="(item, idx) in questions" :key="idx">
              <Input
                :id="idx"
                :label="item.question"
                v-model="item.answer"
                class="border border-[#240F1F1A]/10 rounded-xl focus:outline-none w-full focus:border-[#240F1F1A]/10 focus:ring-0"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="container mt-10">
        <div class="sm:grid md:flex justify-between md:flex-row columns-1 md:columns-2">
          <div class="flex flex-row items-center justify-between md:space-x-32 mb-3 columns-2">
            <div class="grid grid-cols-1 justify-between md:space-x-2 mb-3 columns-1 text-brand-black">
              <p class="text-base font-bold mb-2">Hiring Contact</p>
              <div class="flex flex-row columns-2 w-full">
                <div class="w-1/3">
                  <img :src="hiring_img" alt="profile" class="h-8 w-8 rounded-full object-cover mr-2" />
                </div>
                <div class="w-2/3">
                  <p class="font-bold text-sm m-0 capitalize">{{ hiring_name }}</p>
                  <p class="text-opacity-60 font-light text-xs order-last" v-if="hiring_position">{{ hiring_position }}</p>
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-2 md:flex md:flex-col items-end ml-32">
              <div class="flex items-center cursor-pointer" @click="showMesageModal = true">
                <Chat fill="#6D2E5D" />
                <p class="text-brand-color font-bold text-base hidden md:block ml-2">Send Message</p>
              </div>
            </div>
          </div>
          <div class="sm:grid md:flex md:flex-col items-center">
            <Button
              data-test="send-message-button"
              label="Next"
              v-if="!showMesageModal"
              :color="disabled ? 'blackOpacity' : 'primary'"
              btnSize="w-full md:w-28"
              :disabled="disabled"
              @click="CvUpload"
              :loading="isLoading"
            />
            <Button
              v-if="showMesageModal"
              label="Send"
              :color="disableMessage ? 'blackOpacity' : 'primary'"
              btnSize="w-full md:w-28"
              :disabled="disableMessage"
              @click="sendMessage"
            />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import Chat from '@/assets/icons/chat.svg?inline';
import Close from '@/assets/icons/close.svg?inline';
import Button from '@/components/Button.vue';
import Back from '@/assets/icons/back-brand.svg?inline';
import Modal from '@/components/Modal.vue';
import Input from '@/components/form/Input.vue';
import { alert } from '@/utils/helper';

const store = useStore();

const emits = defineEmits(['close-modal', 'show-cv']);
const props = defineProps({
  jobDescription: {
    type: Object,
    required: true,
  },
  linkedin_url: {
    type: String,
    required: true,
  },
});
const expected_salary = ref({
  value: 0,
  currency: 'USD',
});
const disabled = ref(false);

const notice_period = ref('');

const questions = computed(() => {
  if (!props.jobDescription?.automated_interview_questions) return null;
  const interview_questions = [];
  const questions = { ...props.jobDescription?.automated_interview_questions };
  delete questions.expected_salary;
  delete questions.notice_period;
  for (let key in questions) {
    interview_questions.push({
      question: questions[key],
      answer: '',
    });
  }
  return interview_questions;
});

const isSending = ref(false);
const showMesageModal = ref(false);
const disableMessage = ref(true);
const message = ref('');
const automated_interview_responses = ref({});
const isLoading = ref(false);

const role = (job) => job.role_title || job.title_name;

const currencies = ref([]);

const hiring_img = computed(() => {
  if (props.jobDescription.postedBy) return props.jobDescription.postedBy.avatar_url;
  return props.jobDescription.employer.logo_url;
});
const hiring_name = computed(() => {
  if (props.jobDescription.postedBy) return props.jobDescription.postedBy.name;
  return props.jobDescription.employer.name;
});

const hiring_position = computed(() => {
  if (props.jobDescription.postedBy) {
    const position = props.jobDescription.postedBy.position ? `${props.jobDescription.postedBy.position},` : '';
    return `${position} ${props.jobDescription.employer.name}`;
  }
  return null;
});

const CvUpload = () => {
  automated_interview_responses.value.notice_period = notice_period.value;
  automated_interview_responses.value.expected_salary = `${expected_salary.value.currency} ${expected_salary.value.value}`;
  if (questions.value) {
    let formatted_questions = {};
    for (let index = 0; index < questions.value.length; index++) {
      const element = questions.value[index];
      formatted_questions[index] = {
        key: element.question,
        value: element.answer,
      };
    }
    automated_interview_responses.value = { ...automated_interview_responses.value, ...formatted_questions };
  }
  process.env.NODE_ENV === 'development' ? console.log(automated_interview_responses.value) : '';
  store.commit('global/setJobForm', { key: 'automated_interview_responses', value: automated_interview_responses.value });
  emits('show-cv');
};

watch(expected_salary, (newValue) => {
  if (newValue.value > 0) {
    disabled.value = false;
  } else disabled.value = true;
});

watch(message, (newQuestion, oldQuestion) => {
  if (newQuestion) {
    disableMessage.value = false;
  } else disableMessage.value = true;
});

const sendMessage = async () => {
  try {
    if (!isSending.value && message.value) {
      isSending.value = true;
      await store.dispatch('conversation/createConversation', {
        receiver_identifier: props.jobDescription.employer.participant_identifier,
        type: 'text',
        url: null,
        content: message.value,
      });

      message.value = '';
      isSending.value = false;
      showMesageModal.value = false;

      alert('Message Sent', 'success');
    }
  } catch (error) {
    alert(errorMessage(error), 'error');
  }
};

onMounted(async () => {
  const form = await store.getters['global/getJobForm'];
  if (form.automated_interview_responses) {
    automated_interview_responses.value = form.automated_interview_responses;
    const salary = form.automated_interview_responses.expected_salary.split(' ');
    expected_salary.value.currency = salary[0];
    expected_salary.value.value = salary[1];
    notice_period.value = form.automated_interview_responses.notice_period;
    process.env.NODE_ENV === 'development' ? console.log(form) : '';
  }
  let cur = await store.getters['global/getEnum']('currencies');
  if (!cur) {
    await store.dispatch('global/getEnums');
    cur = await store.getters['global/getEnum']('currencies');
  }
  currencies.value = cur;
});
</script>
<style lang="scss" scoped>
.description::v-deep {
  line-height: 1.8rem;

  ul {
    list-style: disc;
    margin-left: 1rem;
    padding-left: 1rem;
  }
}

.modal-sub-title {
  color: #1b224b;
  text-align: left;
  font-family: 'Albert Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.expected_salary input {
  width: 79.5%;
}

.expected_salary select {
  width: 20%;
}
</style>
